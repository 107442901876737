$(function() {     
            
    $.material.init();    
    
    /*****************************************************************
     * 
     * SWITCH (show|hide) ELEMENT
     * 
     *****************************************************************/ 
    $(document).on("click", ".js-switch", function(e){
        e.preventDefault();
        var switchedBlocks = [];                
        var label = $(this).html();
        
        if ($(this).attr('data-switchfor')) {            
            switchedBlocks.push($('#' + $(this).attr('data-switchfor')));
        }
        if ($(this).attr('data-switchfor-class')) {            
            var tmp = $('.' + $(this).attr('data-switchfor-class'));
            for(i=0; i<tmp.length; i++) {
                switchedBlocks.push($(tmp.get(i)));
            }
        }
        
        var wasHidden;
        
        if (switchedBlocks.length)
        {
            if (switchedBlocks[0].is(':visible')) {
                wasHidden = true;
            }
            else {
                wasHidden = false;
            }
        }

        $.each(switchedBlocks, function(index, switchedBlock)
        {
            switchedBlock.toggle('slow');
        });
        
        if (switchedBlocks.length)
        {
            if (wasHidden) {
                $(this).html(label.replace("Hide", "Show"));
            }
            else {            
                $(this).html(label.replace("Show", "Hide"));
            }
        }
    });
    
    /*****************************************************************
     * 
     * SOURCE SELECT & PASTE
     * 
     *****************************************************************/     
    $('#source-select').change(function() {
        $('.source-dependent').each(function() {
            $(this).hide();
            if ($(this).attr('data-lang') === $('#source-select').val()) {
                $(this).show();
            }
        });
    });
    
    $('#source-select').change(function() {
        var langCode = $(this).val();
        var button = $('#show-source-button');
        
        $('.source').each(function() {
            $(this).hide();
        });
        $('#source-' + langCode).show();
        
        //history: on product page only
        $('.source-history').each(function() {
            $(this).hide();
        });        
        $('#source-history-' + langCode).show();
        
        $('#source').show();
        //button.html(button.html().replace("Show", "Hide"));
    });
    
    $('.paste-source').click(function(e) {  
        e.preventDefault();
        var langCode = $("#source-select option:selected").val();
        var fields = $(this).attr('data-fields').split('|');
        $( "#dialog-confirm" ).dialog({
            resizable: false,
            width: 400,
            modal: true,
            buttons: {
                "OK": function() {    
                    $.each(fields, function(index, element){
                        var attributes = element.split(':');
                        if (attributes.length === 1) {
                            attributes.unshift('input');
                        }
                        var ckeExist = false;
                        if (CKEDITOR.instances[attributes[1]]) {
                            CKEDITOR.instances[attributes[1]].destroy();
                            ckeExist = true;
                        }
                        $(attributes[0] + '[name="' + attributes[1] + '"]').val( $('#source-' + langCode + ' .' + attributes[1]).html() );    
                        if (ckeExist) {
                            CKEDITOR.replace(attributes[1]);
                        }
                    });
                    $(this).dialog( "close" );
                },
                "Cancel": function() {
                    $(this).dialog( "close" );
                }
            }
        });
    }); 
    
    /*****************************************************************
     * 
     * MULTICHECK
     * 
     *****************************************************************/ 
    $('.multicheck').click(function(){
        var actionIsOn = $(this).is(":checked");
        var classSelector = $(this).attr('data-for');
        $('.' + classSelector).each(function() {
            if (actionIsOn && $(this).is(':visible')) {
                $(this).prop('checked', true);
            } else {
                $(this).prop('checked', false);
            }
        });
    });
    
    /*****************************************************************
     * 
     * LANG FILTER
     * 
     *****************************************************************/    
    $('.lang-filter').click(function() {
        var lang = $(this).val();
        if ($(this).is(':checked')) {
            $('.' + lang).each(function() {
                $(this).show();
            });
        }
        else {
            $('.' + lang).each(function() {
                $(this).hide();
            });
        }
    });
    
    $('#select-all').click(function() {
        $('.lang-filter').each(function() {
            if ( ! $(this).is(':checked')) {
                $(this).trigger( "click" );
            }
        });        
    });
    $('#select-none').click(function() {
        $('.lang-filter').each(function() {
            if ($(this).is(':checked')) {
                $(this).trigger( "click" );
            }
        });        
    });  
    
    /*****************************************************************
     * 
     * EXPAND (AJAX) SECTIONS
     * 
     *****************************************************************/     
    $('.expand-button').click(function() {
        if ($(this).attr('data-href')) {
            $.ajax({
                type: "GET",
                dataType: "json",
                url : $(this).attr('data-href'),
                success: function(data) {
                    if (data.status === 'success') {
                        $('#expand-' + data.element_id).html(data.html);
                        if ($( "#tabs-" + data.element_id).length) {
                            $( "#tabs-" + data.element_id).tabs();
                            // products & articles
                            $( "#photos-" + data.element_id).tabs();
                            // products only
                            $( "#specs-" + data.element_id).tabs();
                            $( "#content-" + data.element_id).tabs();
                            // articles only
                            $( "#icons-" + data.element_id).tabs();                          
                        }
                    }
                    launchDateTimePicker();                    
                }
            });
            // not to load this section again
            $(this).removeAttr('data-href');
        }
    }); 
    
    /*****************************************************************
     * 
     * FILTER INPUT
     * 
     *****************************************************************/ 
    $(".searchInput").keyup(function () {
        //split the current value of searchInput
        var data = this.value.split(" ");
        //create a jquery object of the rows
        var row = $("#" + $(this).attr('data-searchbody')).find("tr");
        if (this.value == "") {
            row.show();
            return;
        }
        //hide all the rows
        row.hide();

        //Recusively filter the jquery object to get results.
        row.filter(function (i, v) {
            var $t = $(this);
            for (var d = 0; d < data.length; ++d) {
                if ($t.is(":Contains('" + data[d] + "')")) {
                    return true;
                }
            }
            return false;
        })
        //show the rows that match.
        .show();            
    }); 
    
    $('.columnFilter').click(function(e) {
        e.preventDefault();
        td = $(this).attr('data-fcol');
        $("."+td+":contains('" + $(this).html() + "')").parent().show();
        $("."+td+":not(:contains('" + $(this).html() + "'))").parent().hide();
    })
    
    /*****************************************************************
     * 
     * DATE-TIME PICKER
     * 
     *****************************************************************/     
    function launchDateTimePicker() {
        
        $('.hasDatepicker').datetimepicker({
            locale: 'pl',
            format: 'YYYY-MM-DD HH:mm:ss',
            widgetPositioning: {
                vertical: 'bottom'
            },
            showClose: true
        });
        $(".datepickerBegin").on("dp.change", function (e) {
            var linkedSelector = '.datepickerEnd';
            var linkedElementID = $(this).attr('data-datepicker-linked-element');
            if (typeof  linkedElementID !== 'undefined') {
                linkedSelector = '#' + linkedElementID;
            }
            $(linkedSelector).data("DateTimePicker").minDate(e.date);
        });
        $(".datepickerEnd").on("dp.change", function (e) {
            var linkedSelector = '.datepickerBegin';
            var linkedElementID = $(this).attr('data-datepicker-linked-element');
            if (typeof  linkedElementID !== 'undefined') {
                linkedSelector = '#' + linkedElementID;
            }
            $(linkedSelector).data("DateTimePicker").maxDate(e.date);            
        });
    }
    
    launchDateTimePicker();    
});

/*****************************************************************
 * 
 * TOGGLE MENU
 * 
 *****************************************************************/     

$("#menu-toggle").click(function(e) {
    e.preventDefault();
    $("#wrapper").toggleClass("toggled");
});

$(".menu-left-dropdown").on('show.bs.collapse', function () {
    Cookies.set($(this).attr('id'), 1, { path: '/' });
});
$(".menu-left-dropdown").on('hide.bs.collapse', function () {
    Cookies.set($(this).attr('id'), 0, { path: '/' });
});

/*****************************************************************
 * 
 * RADIO BUTTON
 * 
 *****************************************************************/ 

$(document).on("click", ".cb-enable", function(){
    var parent = $(this).parents('.switch');
    $('.cb-disable',parent).removeClass('selected');
    $(this).addClass('selected');
    $('.checkbox',parent).attr('checked', true);
});

$(document).on("click", ".cb-disable", function(){
    var parent = $(this).parents('.switch');
    $('.cb-enable',parent).removeClass('selected');
    $(this).addClass('selected');
    $('.checkbox',parent).attr('checked', false);
});

/*****************************************************************
 * 
 * STICKY HEAD
 * 
 *****************************************************************/ 

(function ($) {
	$.ay = $.ay || {};
	$.ay.tableSticky	= function (settings) {
		if (!settings.target || !settings.target instanceof $) {
			throw 'Target is not defined or it is not instance of jQuery.';
		}
	
		settings.target.each(function () {
			var thead			= $(this);
			
			var thead_offset	= thead.offset().top;
			
			var present;
			var clone;
			
			$(document).on('scroll', function () {
				var scroll_top	= $(this).scrollTop();
				
				if (present) {
					if (scroll_top < thead_offset) {
						clone.remove();
						
						thead.css({position: 'relative'}).removeClass('ay-position-fixed');
						
						present	= false;
					}
				} else if (scroll_top > thead_offset) {
					clone	= thead.clone();
					
					clone.insertBefore(thead);
					
					$(window).on('resize', function () {
						clone.find('th').each(function (index) {
							thead.find('th').eq(index).css({width: $(this).width()});
						});
					}).trigger('resize');
					
					thead.css({position: 'fixed', top: 0}).addClass('ay-position-fixed');
					
					present	= true;
				}
			});
		});
	};
})($);